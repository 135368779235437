<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="home">
    <img
      src="@/assets/go.png"
      alt="Go"
      oncontextmenu="return false;"
      @click="go"
    />
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  methods: {
    go() {
      this.$router.push('/picker');
    },
  },
};
</script>

<style scoped>
.home {
  height: 100%;
  width: 100%;
}

.home img {
  margin-top: 20%;
  width: 100%;
}
</style>
