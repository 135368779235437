<template>
  <div class="app">
    <router-view/>
  </div>
</template>

<style>
body {
  background: #000;
  margin: 0;
  overscroll-behavior-y: contain;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  align-items: center;
  display: flex;
  font-family: Helvetica, Arial, sans-serif;
  height: 100vh;
  justify-content: center;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100vw;
  text-align: center;
}

.app {
  align-items: center;
  background-image: url('@/assets/bg.png');
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: 100%;
  max-height: calc(100vw * 1.6);
  max-width: calc(100vh * 0.625);
  position: relative;
  width: 100%;
}
</style>
