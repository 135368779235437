<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="picker">
    <div
      class="button"
      @click="sendProjection('0')"
    >
      <img
        src="@/assets/button_0.png"
        alt="0"
        oncontextmenu="return false;"
      />
    </div>
    <div
      class="button"
      @click="sendProjection('1')"
    >
      <img
        src="@/assets/button_1.png"
        alt="1"
        oncontextmenu="return false;"
      />
    </div>
    <div
      class="button"
      @click="sendProjection('2')"
    >
      <img
        src="@/assets/button_2.png"
        alt="2"
        oncontextmenu="return false;"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PickerView',
  data() {
    return {
      timeout: null,
      timeoutTime: process.env.VUE_APP_TIMEOUT ? Number(process.env.VUE_APP_TIMEOUT) : null,
    };
  },
  methods: {
    sendProjection(id) {
      const url = `${process.env.VUE_APP_SERVER_ADDRESS}/api/set-projection/`;

      axios.post(
        url,
        {
          video_id: id,
        },
        {
          headers: {
            Authorization: `Token ${process.env.VUE_APP_SERVER_TOKEN}`,
          },
        },
      );
    },
    finish() {
      // this.sendProjection(0);
      this.$router.push('/');
    },
    setupTimeout() {
      if (!this.timeoutTime) {
        return;
      }

      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(this.finish, this.timeoutTime);
    },
    setupListeners() {
      // eslint-disable-next-line no-restricted-globals
      addEventListener('mousemove', this.setupTimeout);
    },
    removeListeners() {
      // eslint-disable-next-line no-restricted-globals
      removeEventListener('mousemove', this.setupTimeout);
    },
  },
  mounted() {
    this.setupListeners();
    this.setupTimeout();
  },
  updated() {
    this.setupListeners();
    this.setupTimeout();
  },
};
</script>

<style>
.picker {
  margin: auto;
  width: 87.5%;
}

.button {
  width: 100%;
  margin-bottom: 1vh;
}

.button img {
  width: 100%;
}

</style>
