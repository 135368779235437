import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import PickerView from '@/views/PickerView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/picker',
    name: 'picker',
    component: PickerView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
